import React, { useEffect, useState } from "react";
import useSession from "../functions/hooks/useSession";

const eventsToListen = [
  "abort",
  "ended",
  "error",
  "pause",
  "play",
  "stalled",
  "suspend",
  "timeupdate",
  "volumechange",
  "waiting",
];

const trackingUrl = "https://tracking.api.prod.coasy.com/event/pbaa";

const Video = ({ videoObject, loading = "lazy", className = "" }) => {
  const [playerInitialized, setPlayerInitialized] = useState(null);
  const getSessionId = useSession();

  const {
    cloudflareId,
    hasGifThumbnail = false,
    format = "Querformat",
    embeddedCode = "",
    // vimeoObject,
  } = videoObject;

  const initPlayer = () => {
    if (!window.Stream) {
      window.setTimeout(() => {
        initPlayer();
      }, 500);
    } else if (!playerInitialized) {
      const player = window.Stream(
        document.getElementById(`stream-player-${cloudflareId}`)
      );
      for (let eventType of eventsToListen) {
        player.addEventListener(eventType, (playerEvent) => {
          onPlayerEvent(player, playerEvent);
        });
      }
      setPlayerInitialized(true);
    }
  };

  const onPlayerEvent = (player, playerEvent) => {
    const { currentTime } = player;
    const { type } = playerEvent;
    const sessionId = getSessionId();

    const event = {
      source: "video-player",
      sessionId,
      cloudflareId,
      type,
      currentTime,
      event: `video.${type}`,
      url: document.location.href,
    };
    let shouldSend = true;
    if (type === "timeupdate" && Date.now() % 5 !== 0) shouldSend = false;
    if (shouldSend) {
      console.log("sends", event)
      if (navigator.sendBeacon) {
        navigator.sendBeacon(trackingUrl, JSON.stringify(event));
      } else {
        fetch(trackingUrl, {
          method: "POST",
          body: JSON.stringify(event),
        });
      }

      if (window.dataLayer) window.dataLayer.push(event);
    }
  };

  useEffect(() => {
    initPlayer();
  }, []);

  const paramString = [
    hasGifThumbnail &&
      `poster=${encodeURIComponent(
        `https://videodelivery.net/${cloudflareId}/thumbnails/thumbnail.gif?duration=10s`
      )}`,
    loading === "eager" && "preload=true",
  ]
    .filter(Boolean)
    .join("&");

  let src = `https://iframe.videodelivery.net/${cloudflareId}?${paramString}`;

  return (
  <>
    <div
      className={`flex items-center justify-center place-content-center ${className}`}
    >
      <div
        className={
          format === "Hochformat"
            ? "w-full max-w-md"
            : "w-full max-w-xl lg:max-w-5xl"
        }
      >
        <div
          className={
            format === "Hochformat"
              ? "aspect-h-16 aspect-w-9"
              : "aspect-h-9 aspect-w-16"
          }
        >
          {/* {!vimeoObject.vimeoId ? (
            <iframe
              src={src}
              loading={loading}
              title="Video"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
            />
          ) : (
            <iframe
              src={`https://player.vimeo.com/video/${vimeoObject.vimeoId}?h=${videoObject.vimeoHash}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
              frameborder="0"
              loading={loading}
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title="Rough Cut"
            />
          )} */}
          <VideoContainer
            // vimeoObject={vimeoObject}
            src={src}
            loading={loading}
            embeddedCode={embeddedCode}
            id={`stream-player-${cloudflareId}`}
          />
        </div>
      </div>
    </div>
    <script src="https://embed.videodelivery.net/embed/sdk.latest.js"></script>

    </>
  );
};

export default Video;

function VideoContainer({
  // vimeoObject,
  src,
  embeddedCode,
  loading,
  id,
}) {
  if (embeddedCode) {
    return <div dangerouslySetInnerHTML={{ __html: embeddedCode }}></div>;
  }

  /* TODO: Fix bug, so that id and hash can be embedded. This currently doesn't work in some browsers
    if (vimeoObject) {
    return (
      <iframe
        src={`https://player.vimeo.com/video/${vimeoObject.vimeoId}?h=${vimeoObject.vimeoHash}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
        frameborder="0"
        loading={loading}
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        title="Rough Cut"
      />
    );
  } */

  return (
    <iframe
      id={id}
      src={src}
      loading={loading}
      title="Video"
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
      allowfullscreen="true"
    />
  );
}
